'use strict';

/**
 * @ngdoc function
 * @name informaApp.directive:infSlider
 * @description
 * # infSlider
 * Directive of the informaApp
 */
angular.module('informaApp')
  .directive('infSlider', function () {

    return{
      restrict: 'E',
      scope:{
        slider: '=model'
      },
      templateUrl: 'directives/inf-slider/template.ptl.html'
    }

  });
